// Contact us Page

import React, { Component } from "react"
import { graphql } from "gatsby"
import axios from "axios"

import SEO from "../components/seo"
import Typed from "react-typed"
import ScroolTop from '../components/scrolltop'
import Recaptcha from 'react-recaptcha';

const validEmailRegex = RegExp(
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
)
const validPhoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
)

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      status: "",
      fullname: null,
      company: ' ',
      email: null,
      phone: null,
      websiteurl: null,
      foundus: null,
      message: null,
      errors: {
        fullname: "",
        company: null,
        email: "",
        phone: "",
        websiteurl: "",
        foundus: "",
        message: "",
      },
    }
  }

  allFieldsValid() {
    const fields = [
      "fullname",
      "email",
      "phone",
    ]
    const errors = {}

    fields.forEach(field => {
      const value = this.state[field]

      if (!value || value === "") {
        errors[field] = "This field cannot be blank."
      }

      if (field === "email") {
        if (!value || value === "") {
          errors.email = "This field cannot be blank."
        } else if (!value.match(validEmailRegex)) {
          errors.email = "Please enter a valid email address"
        }
      }
      if (field === "phone") {
        if (!value || value === "") {
          errors.phone = "This field cannot be blank."
        } else if (!value.match(validPhoneRegex)) {
          errors.phone = "Please enter only digits (0-9)"
        }
      }
    })

    this.setState({ errors })

    return Object.keys(errors).length === 0
  }
  handleChange = event => {
    event.preventDefault()
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  captchacallback = event => {
    console.log('Captch load');
  }
  handleSubmit = event => {
    event.preventDefault()
    if (this.allFieldsValid()) {
      const {
        fullname,
        company,
        email,
        phone,
        websiteurl,
        foundus,
        message,
      } = this.state
      axios
        .post("https://static.finntia.com/wp-json/finntia/v1/contact-us", {
          fullname: fullname,
          company: company,
          email: email,
          phone: phone,
          websiteurl: websiteurl,
          foundus: foundus,
          message: message,
        })
        .then(response => {
          this.setState({
            submitted: true,
            status:
              "Thank you. We've received your Inquiry. We'll get back to you soon.",
          })
        })
        .catch(error => {
          console.log(error)
        })
    } else {
      this.setState({
        submitted: false,
        status:
          "There was a problem with your submission. Errors are marked below.",
      })
    }
  }

  render() {
    const data = this.props.data
    const acf = data.allWordpressPage.edges[0].node.acf;
    const content = data.allWordpressPage.edges[0].node.acf.content_modules_page[0].fwc_content;
    var oldString = acf.banner_blink_word;
    var mynewarray=oldString.split(',')
    // content_modules_page
    //console.log(content);
    return (
      <>
        <SEO title="Contact us" />

        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <h5>
                  01.<span className="line"></span>Inquiry Us
                </h5> */}
                <h1>
                  {acf.banner_content}{" "}
                  <Typed
                    strings={mynewarray}
                    typeSpeed={120}
                    backSpeed={50}
                    cursorChar={"_"}
                    loop
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section className="contact-page">
          <div className="contact-form-wraper">
            <div className="container">
              <div className="row">
                <div className="col-md-12 contact-top-text" dangerouslySetInnerHTML={{ __html: content }} />                
                <div className="col-lg-6">
                  <div className="contact-info">
                    {/* <div className="title">
                      <h5>
                        01.<div className="line"></div>Write to us
                      </h5>
                    </div> */}
                    <address>
                      Maria 01
                      <br />
                      Lapinlahdenkatu 16, 00180 <br />
                      Helsinki, Finland
                    </address>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact-form">
                    <h3
                      className={
                        this.state.submitted === true
                          ? "mail-send"
                          : this.state.submitted === false
                          ? "mail-failed"
                          : ""
                      }
                    >
                      {this.state.status}
                    </h3>
                    {this.state.submitted === false && (
                      <form
                        method="post"
                        action="#"
                        className="frm_forms"
                        onSubmit={this.handleSubmit}
                        noValidate
                      >
                        <div className="row cf-row">
                          <div className="col-md-6">
                            <div className="cf-field">
                              <input type="text" name="fullname" id="fullname" className="form-control fullname" onChange={this.handleChange} noValidate placeholder="Your Name*" />
                              {this.state.errors.fullname && (
                                <span className="error">
                                  {this.state.errors.fullname}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="cf-field">
                              <input
                                type="text"
                                name="company"
                                id="company"
                                className="form-control company"
                                onChange={this.handleChange}
                                placeholder="Company"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="cf-field">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                className="form-control email"
                                onChange={this.handleChange}
                                noValidate
                                placeholder="E-mail*"
                              />
                              {this.state.errors.email && (
                                <span className="error">
                                  {this.state.errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="cf-field">
                              <input
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control phone"
                                onChange={this.handleChange}
                                noValidate
                                placeholder="Phone*"
                              />
                              {this.state.errors.phone && (
                                <span className="error">
                                  {this.state.errors.phone}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="cf-field">
                              <input
                                type="text"
                                name="websiteurl"
                                id="websiteurl"
                                className="form-control websiteurl"
                                onChange={this.handleChange}
                                placeholder="Website URL"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="cf-field">
                              <textarea
                                name="foundus"
                                id="foundus"
                                rows="5"
                                className="form-control foundus"
                                onChange={this.handleChange}
                                placeholder="How you found us?"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="cf-field">
                              <textarea
                                name="message"
                                id="message"
                                rows="5"
                                className="form-control message"
                                onChange={this.handleChange}
                                placeholder="How Can We Help You?"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                          <Recaptcha
                            sitekey="6LemXPMUAAAAAN2-JWIs0xe8jj62t0sei4KElZem"
                            render="explicit"
                            onloadCallback={this.captchacallback}
                          />
                          </div>
                          <div className="col-md-12">
                            <div className="cf-field submit-btn">
                              <button className="btn-primary btn" type="submit">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="map-section">
            <iframe width="100%" height="500" src="https://maps.google.com/maps?width=100%&amp;height=500&amp;hl=en&amp;q=Maria%2001%20Lapinlahdenkatu%2016%2C%2000180%20Helsinki%2C%20Finland+(Finntia)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" ></iframe>            
          </div>
        </section>
        <ScroolTop />
      </>
    )
  }
}
export default ContactUs

export const query = graphql`
  {
    allWordpressPage(filter: { wordpress_id: { eq: 260 } }) {
      edges {
        node {
          acf {
            banner_content
            banner_blink_word
            contact_heading
            contact_content
            contact_form_shortcode
            google_map
            content_modules_page {
              ... on WordPressAcf_full_width_content {
                fwc_content
              }
            }
          }
        }
      }
    }
   
  }
`
